import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../../actions/auth";
import { CLEAR_MESSAGE, SET_MESSAGE } from "./../../actions/types";
import { displayOrder } from "./../../services/user.service";

// const API_Base_URL = "https://api.metageo.io/";
// export const API_URL = "https://api.metageo.io/";
export const API_URL = "https://devapi.metageo.io/";
export const ICON_URL = "https://dev.metageo.io/images/";
export const GEOSERVER_WORKSPACE = "metageo-dev:";
//const BASEMAP_INDEX=11 -->For Sentinal-2
//const BASEMAP_INDEX=0 -->For Bing Maps Aerial
export const BASEMAP_INDEX = 11;
//console.log("Process.env",process.env.API_URL);
export const allowedUser = [2, 5, 6, 7];

export const industries = [
  {
    value: "Architecture",
    label: "Architecture",
  },
  {
    value: "Construction",
    label: "Construction",
  },
  {
    value: "Engineering / Survey",
    label: "Engineering / Survey",
  },
  {
    value: "Energy",
    label: "Energy",
  },
  {
    value: "Inspection",
    label: "Inspection",
  },
  {
    value: "Government",
    label: "Government",
  },
  {
    value: "Environmental",
    label: "Environmental",
  },
  {
    value: "Education",
    label: "Education",
  },
  {
    value: "Public Safety / Disaster Management",
    label: "Public Safety / Disaster Management",
  },
  {
    value: "Insurance",
    label: "Insurance",
  },
  {
    value: "Utilities",
    label: "Utilities",
  },
  {
    value: "Other",
    label: "Other",
  },
];
export const findPath = (object, key, value) => {
  const path = [];
  const pathName = [];
  const keyExists = (obj) => {
    if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
      return false;
    } else if (obj.hasOwnProperty(key) && obj[key] === value) {
      return true;
    } else if (Array.isArray(obj)) {
      let parentKey = path.length ? path.pop() : "";

      for (let i = 0; i < obj.length; i++) {
        if (obj[i]) {
          path.push(`${parentKey}[${i}]`);
          pathName.push(obj[i].name);
          const result = keyExists(obj[i], key);
          if (result) {
            return result;
          }
          path.pop();
          pathName.pop();
        }
      }
    } else {
      for (const k in obj) {
        if (k !== "entity") {
          path.push(k);
          const result = keyExists(obj[k], key);
          if (result) {
            return result;
          }
          path.pop();
        }
      }
    }

    return false;
  };
  keyExists(object);
  return pathName.join("/");
};
export const handleClick = (key, history) => {
  try {
    // window["scrollTo"]({ top: 2000, behavior: "smooth" });
    document
      .getElementById(key)
      .scrollIntoView({ behavior: "smooth", block: "start" });
  } catch (e) {
    history.push("/");
  }
};
export const config = {
  BUCKET_PNG: "dev-mg-png",
  BUCKET_LAS: "dev-pointscloud-input",
  BUCKET_JPG: "dev-mg-jpg",
  BUCKET_KML: "dev-mg-kml",
  BUCKET_DXF: "dev-mg-dxf",
  BUCKET_GEO: "dev-mg-geojson",
  BUCKET_GPX: "dev-mg-gpx",
  BUCKET_TIFF: "dev-mg-tiff",
  BUCKET_MISC: "dev-mg-misc",
  BUCKET_ZIP: "dev-mg-zip",
  ACCESS_ID: "AKIAY4IJTN7CDNYA7YWQ",
  SECRET_KEY: "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",
  REGION: "us-west-1",
};
export const Alert = () => {
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message, shallowEqual);

  const closeMessage = () => {
    dispatch({ type: CLEAR_MESSAGE });
    // dispatch({ type: SET_MESSAGE, payload: { message: "", severity: "", isOpen: false } });
  };
  if (!message?.html) {
    if (
      (message?.message?.toLowerCase().includes("valid session") ||
        message?.message?.toLowerCase().includes("expired") ||
        message?.message
          ?.toLowerCase()
          .includes("could not decode access token")) &&
      !message?.pass
    ) {
      let history = useHistory();
      history.push("/login");
      dispatch(logout());
      history.push("/login");
      dispatch(displayMessage("error", "Session expired due another sign in"));
      return (
        <Snackbar
          open={message?.isOpen}
          autoHideDuration={10000}
          onClose={() => closeMessage()}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => closeMessage()}
            severity={message?.severity || "error"}
          >
            Session Expired
          </MuiAlert>
        </Snackbar>
      );
    }
  }
  if (message?.pass) {
    return null;
  }
  return (
    <Snackbar
      open={message?.isOpen}
      autoHideDuration={10000}
      onClose={() => closeMessage()}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={() => closeMessage()}
        severity={message?.severity || "error"}
      >
        {message?.message == "Network Error" ||
        message?.message ==
          "Full authorization is required to access this route" ? (
          "The server is currently busy, please wait a moment."
        ) : message?.html ? (
          message?.message
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: message?.message,
            }}
          ></div>
        )}
      </MuiAlert>
    </Snackbar>
  );
};

export const updateFolderInProjectdateCollected = (
  children,
  folderId,
  dateCollected
) => {
  return children?.forEach((item) => {
    if (item.isFolder) {
      if (item.id === folderId) {
        item.dateCollected = dateCollected;
      } else {
        updateFolderInProjectdateCollected(
          item.children,
          folderId,
          dateCollected
        );
      }
    }
  });
};

export const insertItemInProject = (children, folderId, newItem) => {
  return children?.forEach((item) => {
    if (item.isFolder) {
      if (item.id === folderId) {
        item.children.push(newItem);
      } else {
        insertItemInProject(item.children, folderId, newItem);
      }
    }
  });
};
export const updateItemInProject = (
  children,
  folderId,
  layerId,
  entity,
  status
) => {
  return children?.forEach((item) => {
    if (item.isFolder) {
      if (item.id === folderId) {
        item.children.forEach((layer) => {
          if (layer.id == layerId) {
            layer.entity = entity;
            if (status) {
              layer.status = status;
            }
          }
        });
      } else {
        updateItemInProject(item.children, folderId, layerId, entity, status);
      }
    }
  });
};

export const updateItemInProjectImages = (
  children,
  folderId,
  layerId,
  entity,
  status,
  tags
) => {
  return children?.forEach((item) => {
    if (item.isFolder) {
      if (item.id === folderId) {
        item.children.forEach((layer) => {
          if (layer.id == layerId) {
            layer.entity = entity;

            status && (layer.status = status);
            tags?.length > 0 &&
              layer.feature_attributes?.length > 0 &&
              (layer.feature_attributes[0].tags = tags);
            //layer["image_taggings"] = [];
          }
        });
      } else {
        updateItemInProjectImages(
          item.children,
          folderId,
          layerId,
          entity,
          status,
          tags
        );
      }
    }
  });
};

export const updateItemInProjectAttachment = (
  children,
  folderId,
  layerId,
  attatchment
) => {
  return children?.forEach((item) => {
    if (item.isFolder) {
      if (item.id === folderId) {
        item.children.forEach((layer) => {
          if (layer.id == layerId) {
            layer.attatchment = attatchment;
          }
        });
      } else {
        updateItemInProjectAttachment(item.children, folderId, layerId, entity);
      }
    }
  });
};
export const updateItemInProjectReviewStatus = (
  children,
  folderId,
  layerId,
  reviewStatus
) => {
  return children?.forEach((item) => {
    if (item.isFolder) {
      if (item.id === folderId) {
        item.children.forEach((layer) => {
          if (layer.id == layerId) {
            layer.reviewStatus = reviewStatus;
          }
        });
      } else {
        updateItemInProjectReviewStatus(
          item.children,
          folderId,
          layerId,
          entity
        );
      }
    }
  });
};

export const updateItemInProjectTileSet = (
  children,
  folderId,
  layerId,
  entity
) => {
  console.log(
    "children",
    children,
    "folderId",
    folderId,
    "layerId",
    layerId,
    "entity",
    entity
  );
  if (children.length > 0) {
    return children?.forEach((item) => {
      if (item.isFolder) {
        if (item.id === folderId) {
          item.children.forEach((layer) => {
            if (layer.id == layerId) {
              layer.entity = entity;
              layer.status = { status: "intervalDone" };
            }
          });
        } else {
          updateItemInProjectTileSet(item.children, folderId, layerId, entity);
        }
      }
    });
  }
};
export const removeItemFromProject = (children, folderId, itemId) => {
  children?.forEach((item) => {
    if (item.isFolder) {
      if (item.id === folderId) {
        item.children.splice(
          item.children.findIndex((a) => a.id === itemId),
          1
        );
      } else {
        removeItemFromProject(item.children, folderId, itemId);
      }
    }
  });
};
export const searchItemInProject = (
  children,
  folderId,
  itemId,
  sortType,
  type
) => {
  let dataToReturn = null;
  children?.forEach((item) => {
    if (item.isFolder) {
      if (item.id === folderId) {
        let tempData = [...sortFnc(item.children, sortType)];
        tempData = tempData.filter(
          (itemFil) => itemFil.layerType == 5 && itemFil.exifData
        );
        let index = tempData.findIndex((a) => a.id === itemId);
        dataToReturn = tempData[index + type];
      } else {
        searchItemInProject(item.children, folderId, itemId, sortType, type);
      }
    }
  });
  return dataToReturn;
};
export const displayMessage = (type, message) => (dispatch) => {
  dispatch({
    type: SET_MESSAGE,
    payload: { message: message, severity: type, isOpen: true },
  });
};
export const getIdFromString = (itemObject) => {
  displayOrder(itemObject)
    .then((res, error) => {
      if (error) {
        console.log(error);
        return;
      }
      const projects = res.data;
      console.log(projects);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getFolderOrder = (children, folderId) => {
  let displayOrder = null;
  children?.forEach((item) => {
    if (item.isFolder) {
      if (item.id === folderId) {
        displayOrder = item.children.length;
      } else {
        getFolderOrder(item.children, folderId);
      }
    }
  });
  return displayOrder;
};
export const validURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};
export const getYoutubeId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

// {{base}}/projects/images?projectId=6&folderId=217
export function hexToRgbA(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return [(c >> 16) & 255, (c >> 8) & 255, c & 255, 1];
  }
  throw new Error("Bad Hex");
}
export function sortFnc(arr, type) {
  if (type == 1)
    return arr?.sort(function(a, b) {
      var nameA = a.name.toLowerCase(),
        nameB = b.name.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  if (type == 2)
    return arr?.sort(function(a, b) {
      var nameA = a.name.toLowerCase(),
        nameB = b.name.toLowerCase();
      if (nameA < nameB) return 1;
      if (nameA > nameB) return -1;
      return 0;
    });
  if (type == 3)
    return arr?.sort(function(a, b) {
      var nameA = new Date(a.createdAt),
        nameB = new Date(b.createdAt);
      if (nameA < nameB) return 1;
      if (nameA > nameB) return -1;
      return 0;
    });
  if (type == 4)
    return arr?.sort(function(a, b) {
      var nameA = new Date(a.createdAt),
        nameB = new Date(b.createdAt);
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
}

export const checkSld = (arr, fid, type) => {
  let check = arr.findIndex((rule) => rule.name == fid + "_" + type);
  return check;
};

export const convertSld = (arr, fid, type) => {
  let tempArr = arr.map((itemRule) => {
    if (
      !itemRule.name.toString().includes("line") &&
      !itemRule.name.toString().includes("point") &&
      !itemRule.name.toString().includes("polygon")
    ) {
      if (itemRule.symbolizers[0].kind == "Fill") {
        itemRule.name = itemRule.name + "_polygon";
      } else if (itemRule.symbolizers[0].kind == "Line") {
        itemRule.name = itemRule.name + "_line";
      } else if (itemRule.symbolizers[0].kind == "Icon") {
        itemRule.name = itemRule.name + "_point";
      }
    }
    return itemRule;
  });
  return tempArr;
};
